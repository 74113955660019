import {
  BrowserRouter as Router,
  Switch,
  Route
} from "react-router-dom";


import Empower from "./pages/Empower";

function App() {
  return (
    <Router>
    <div>
      <Switch>
        <Route path="/empower">
          <Empower />
        </Route>
      </Switch>
    </div>
  </Router>
  );
}

export default App;
